import Cookie from "@/core/services/localstorage.module";

function GpCars() {
	/*var DataGroupeToPush = [];
	DataGroupeToPush[0] = {id:0,label:Cookie.GetCookie('name_customer'),type:"G"};
	var groupe_management = Cookie.GetCookie('groupe_management');
	for (var i in groupe_management){
		
		if(!groupe_management[i].id_sg){
			DataGroupeToPush[i] = {id:i,label:groupe_management[i].name,name:groupe_management[i].name,type:"G",children:[]};
		}
	}
	for (i in groupe_management){
		console.log(groupe_management[i]);
		if(groupe_management[i].id_sg){
			DataGroupeToPush[groupe_management[i].id_sg]["children"][i] = {id:i,label:groupe_management[i].name,name:groupe_management[i].name,type:"G",children:[]};
		}
	}
	return DataGroupeToPush;*/
	
	
	
	var DataGroupeToPush = [];
	var DataGroupeIndex = [];
	var groupe_management = Cookie.GetCookie('groupe_management');
	for (var i in groupe_management){
		
		if(!groupe_management[i].id_sg){
			DataGroupeToPush.push({id:i,label:groupe_management[i].name,type:"G",children:[]});
			DataGroupeIndex[i] = DataGroupeToPush.length - 1;
		}
	}
	for (i in groupe_management){
		console.log(groupe_management[i]);
		if(groupe_management[i].id_sg){
			DataGroupeToPush[DataGroupeIndex[groupe_management[i].id_sg]]["children"].push({id:i,label:groupe_management[i].name,type:"G",children:[]});
		}
	}
	return DataGroupeToPush;
}

function GetCarsByGp(Id_Gp) {
	var DataCarsToPush = [];
	var mindata = Cookie.GetCookie('mindata');
	for (var i in mindata){
		if(mindata[i].id_groupe == Id_Gp){
			DataCarsToPush[i] = mindata[i];
		}
	}
	return DataCarsToPush;
	
	

}
async function GetCarsByKey(id_car,mindata) {
	//var mindata = Cookie.GetCookie('mindata');
	for (var i in mindata){
		if(mindata[i].id == id_car){
			return i;
		}
	}
	return 0;
}
async function GetGroupeSubGroup(array_groupe) {
	var groupe_management = Cookie.GetCookie('groupe_management');
	var res = [];
	if(array_groupe.length == 0){
		/*for (var y in groupe_management){
				res.push(parseInt(y));
		}*/
		return false;
	}
	
	for(var k in array_groupe){
		res.push(parseInt(array_groupe[k]));
		for (var i in groupe_management){
			if(groupe_management[i].id_sg == parseInt(array_groupe[k])){
				res.push(parseInt(i));
			}
		}
	}
	return res;
}


export default {
	GpCars,
	GetCarsByGp,
	GetCarsByKey,
	GetGroupeSubGroup,
};

