<template>
	<div style="margin: 0 -12px 0 -12px !important;">
		<LoadingProcess  v-if="loadingBar"></LoadingProcess>
		<template>
  <div>
    <b-sidebar id="trajet-sidebar" title="" shadow right  width="95%">
      <TrajetView :key="TrajetId" :id="TrajetId">
      </TrajetView>
	</b-sidebar>
	<b-sidebar id="statistics-sidebar" title="" shadow right  width="95%">
      <StatisticsView :key="StatId" :id="StatId">
      </StatisticsView>
	</b-sidebar>  
	<b-sidebar id="my-sidebar" title="" shadow right  width="95%">
      <div class="row">
      <div class="col-6">
       <div class="offcanvas-content px-10">
				<div class="tab-content">
					<div class="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed" >
						<div class="d-flex align-items-center">
							<div class="symbol symbol-35px symbol-circle">
								<v-badge avatar left overlap>
									<template v-slot:badge>
										<v-avatar>
											<b-badge href="#" :variant="itemsDataCarsLeft.color_ignition"  style="color:transparent">X</b-badge>
										</v-avatar>
									</template>
									<v-avatar size="40" v-if="itemsDataCarsLeft.img_constructeur">
										<v-img :src="itemsDataCarsLeft.img_constructeur"></v-img>
									</v-avatar>
									<v-avatar size="40" v-else>
										<v-img src="media/icon/cars.png"></v-img>
									</v-avatar>
								</v-badge>
							</div>
							<div class="ms-5">
								<a href="javascrit:;" class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">
									<h5 class="font-weight-bold">{{GetNameOrMat(itemsDataCarsLeft)}}</h5>
								</a>
								<div class="fw-bold text-muted">{{itemsDataCarsLeft.range_last_ignition}}</div>
							</div>
							<div class="ms-1" @click="showModalUpdateCars()" style="position: absolute;right: 35px;">
								<span class="svg-icon svg-icon-3">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988
										18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 
										21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black"></path>
										<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245
										10.507L13.489 4.75098L4.13499 14.105Z" fill="black"></path>
									</svg>
								</span>
							</div>
						</div>
					</div>



					<ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 " role="tablist">
						<li style="cursor: pointer;" v-on:click="setActiveTab" data-tab="0" data-toggle="tab"  role="tab" aria-selected="true" class="nav-link active">
							<a href="javascript:;" style="width: 34px;text-align: center;display: block;"   >
								<i class="fa fa-map-marker"></i>
							</a>
						</li>
						<li style="cursor: pointer;display:none" class="nav-link" v-on:click="setActiveTab" data-tab="1" data-toggle="tab" role="tab" aria-selected="false">
							<a style="width: 34px;text-align: center;display: block;"  href="javascript:;" >
								<i class="fa fa-project-diagram"></i>
							</a>
						</li>
						<li style="cursor: pointer;" class="nav-link" v-on:click="setActiveTab" data-tab="2" data-toggle="tab" role="tab" aria-selected="false">
							<a style="width: 34px;text-align: center;display: block;"  href="javascript:;">
								<i class="fa fa-exclamation-triangle"></i>
							</a>
						</li>
					</ul>
					<div class="offcanvas-content">
						<div class="tab-content">
							<b-tabs class="hide-tabs" v-model="tabIndex">
								<b-tab active>
									<div class="scroll pr-7 mr-n7" id="kt_quick_panel_logs">
										<h6 class="font-weight-bold mt-2" style="border-bottom: 1px solid #eee;">{{$tc("TEXT_ALL.LOCATION")}}</h6>
										<div class="row">
											<div class="col-md-10 fs-3" style="font-size:11px">
												<img style="width: 11px;top: -2px;position: relative;" :src="itemsDataCarsLeft.speed == 0 ? 'media/icon/parking-icon.png' : 'media/icon/pos-icon-move.png'" /> ,{{moment(parseFloat(itemsDataCarsLeft.rtc )).format("hh:mm:ss")}} ,  <b>{{itemsDataCarsLeft.adresse}}</b>
											</div>
											<div class="col-md-2 fs-3" v-if="itemsDataCarsLeft.location">
												<a target="_blank"  :href="'http://maps.google.com/?q='+itemsDataCarsLeft.location.lat+','+itemsDataCarsLeft.location.lng">
													<i style="color: #3b77db;" class="fa fa-map"></i>
												</a>
											</div>
										</div>
										<br>
									
										
										<h6 class="font-weight-bold mt-2" style="border-bottom: 1px solid #eee;">Vehicle</h6>
										<div class="row">
											<div class="col-md-6 fs-3" style="font-size:11px">
												<p style="margin-bottom: 2px;color: #7a7a84;">{{$tc("TEXT_ALL.VIN_NUMBER")}} : </p>
												<b>{{itemsDataCarsLeft.vin}}</b>
											</div>
											<div class="col-md-6 fs-3" style="font-size:11px">
												<p style="margin-bottom: 2px;color: #7a7a84;">{{$tc("TEXT_ALL.ODOMETER")}} : </p>
												<b>{{itemsDataCarsLeft.odometer_text}}</b>
											</div>
										</div>
										<br>
										<div class="row">
											<div class="col-md-6 fs-3" style="font-size:11px">
												<p style="margin-bottom: 2px;color: #7a7a84;">{{$tc("TEXT_ALL.SPEED")}} : </p>
												<b>{{itemsDataCarsLeft.speed_text}}</b>
											</div>
											<div class="col-md-6 fs-3" style="font-size:11px">
												<p style="margin-bottom: 2px;color: #7a7a84;">{{$tc("TEXT_ALL.STATUS")}} : </p>
												<b>{{itemsDataCarsLeft.param1_text}}</b>
											</div>
										</div>
										<br>
										<div class="row">
											<div class="col-md-6 fs-3" style="font-size:11px">
												<p style="margin-bottom: 2px;color: #7a7a84;">{{$tc("TEXT_ALL.POWER_VOLTAGE")}} : </p>
												<b>{{itemsDataCarsLeft.main_power_voltage}} V</b>
											</div>
											<div class="col-md-6 fs-3" style="font-size:11px">
												<p style="margin-bottom: 2px;color: #7a7a84;">{{$tc("TEXT_ALL.FUELLEVEL")}} : </p>
												<b>{{itemsDataCarsLeft.fuelLevel_text}}</b>
											</div>
										</div>
										<div class="row">
											<div class="col-md-6 fs-3" style="font-size:11px">
												<p style="margin-bottom: 2px;color: #7a7a84;">{{$tc("TEXT_ALL.IMMATRICULATION")}} : </p>
												<b>{{itemsDataCarsLeft.immatriculation}}</b>
											</div>
											<div class="col-md-6 fs-3" style="font-size:11px">
												
											</div>
										</div>
									</div>
								</b-tab>

								<b-tab>
									<div class="row">
										<div class="col-md-5">
											<input type="text" class="form-control">
										</div>
										<div class="col-md-5">
											<input type="text" class="form-control">
										</div>
										<div class="col-md-2">
										</div>
									</div>
								</b-tab>

								<b-tab>
									<br>
									<div class="d-flex align-items-center mb-9 rounded p-5 bg-light-danger" v-for="(ADrive,key) in AlertDrive" :key="key" >
										<div class="d-flex flex-column flex-grow-1 mr-2">
											<a href="javascript:;" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
												{{$tc("PARAMS."+ADrive.index)}}
											</a>
											<span class="text-muted font-weight-bold">
												{{ moment(parseFloat(ADrive.timestamp)).fromNow() }}
											</span>
										</div>
										<span class="font-weight-bolder py-1 font-size-lg text-success" style="text-align: center;">
											{{moment(parseFloat(ADrive.timestamp)).format("DD/MM/YYYY")}}<br>
											{{moment(parseFloat(ADrive.timestamp)).format("hh:mm:ss")}}
										</span>
									</div>
									<div class="d-flex align-items-center mb-9 rounded p-5 bg-light-success" v-if="AlertDrive.length == 0">
										<div class="d-flex flex-column flex-grow-1 mr-2">
											<a href="javascript:;" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">
												Pas de nouvelles alerts
											</a>
										</div>
										
									</div>
								</b-tab>
							</b-tabs>
						</div>
					</div>
				</div>
		   </div>
      </div>
	  <div class="col-6">
		<l-map v-bind:style="{width: '100%',height: '500px'}" :zoom="zoomS" :center="centerS" @update:zoom="zoomUpdatedS" @update:center="centerUpdatedS" >
				<v-rotated-marker  
					ref="marker"
					v-for="(itemsDataCar,key) in itemsDataCarsMapsS"
					:key="key" 
					:lat-lng="[itemsDataCar.location.lat,itemsDataCar.location.lng]" 
					:icon="itemsDataCar.ignition == 0 ? icon : icon_move" 
					:rotationAngle="itemsDataCar.ignition == 0 ? 0 : itemsDataCar.heading">
					<l-popup ref="popup" :options="{closeOnClick: false,autoClose: false, openPopup:true}">
						<img style="width: 12px;" :src="itemsDataCar.speed == 0 ? 'media/icon/parking-icon.png' : 'media/icon/pos-icon-move.png'" /> <b style="font-size: 10px;">{{GetNameOrMat(itemsDataCar)}}</b>
					</l-popup>
				</v-rotated-marker>
			<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
		</l-map>
	  </div>
      </div>
    </b-sidebar>
  </div>
</template>
		<!--<div v-if="showLeft" id="kt_quick_panel" ref="kt_quick_panel" class="offcanvas-tlmtk offcanvas-left pt-5 pb-10 offcanvas-on" :style="{left: showLeftMargin}">
		   <div class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5" style="" kt-hidden-height="45">
				
				<div class="offcanvas-close mt-n1 pr-5" >
					
					<a @click="CloseLeftMenu()" style="display: block;right: 15px;position: absolute;background: #f64e60;border: red;padding: 2px 0 0 0px;" href="javascript:;" id="kt_quick_panel_close" class="btn btn-xs btn-icon btn-light btn-hover-primary">
						<i class="ki ki-close icon-xs text-muted"></i>
					</a>
				</div>
		   </div>
		   
		</div>-->
		<div class="row">
			<div class="col-md-3" style="background:#fff;padding: 0 0 0 12px;" id="BLockCars">
				<div style="margin-top: 8px;display: block;"><b >Véhicules</b> ({{mindata.length}})</div>
				<hr style="margin: 7px 0;">
				<div class="row">
					<div class="col-md-5" style="margin: 0;padding: 0 0 0 13px;">
						<select class="form-control" v-model="FilterGroupeId"  >
							<option value="">Tous les groupes</option>
							<option v-for="gp in SelectGpOptions" :key="gp.id" :value="gp.id">{{gp.label}}</option>
						</select>
					</div>
					<div class="col-md-5" style="margin: 0;padding: 0 0 0 4px;">
						<div class="input-group">
							<input type="text" class="form-control" v-model="FilterName" placeholder="Par nom" aria-label="Recipient's username" aria-describedby="button-addon2">
							<div class="input-group-append"> 
								<button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="ClearFilterName()">X</button>
							</div>
						</div>

					</div>
					<div class="col-md-2" style="margin: 0;padding: 0">
						<button class="btn btn-primary font-weight-bold" style="margin: 0px 0px 0px 8px;" type="button" @click="FilterByGroupe()">
							<i style="color:rgb(255, 255, 255);" class="fas fa-search"></i>
						</button>
					</div>
					
				</div>
				<hr style="margin: 7px 0 0 0px;">
				<div class="aside-secondary d-flex flex-row-fluid" style="border-left: 1px solid #eee;">
					<div class="aside-workspace scroll scroll-push my-2 ps ps--active-y" style="height:auto;">
						<div class="tabs hide-tabs">
							<div class="tab-content">
									<div class="card card-custom card-stretch">
										<div v-bind:style="{padding: '13px',height: Height,overflow: 'auto'}" >
											<div class="d-flex flex-wrap align-items-center pb-5 pt-5" style="border-bottom: 1px solid #eee;" v-for="itemsDataC in itemsDataCars" :key="itemsDataC.id">
												<div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3" v-b-toggle.my-sidebar @click="OpenLeftMenu(itemsDataC.id)" style="width: 81%;">
													<a href="javascript:;" style="font-size: 10px;" class="text-dark-50 font-weight-bolder text-hover-primary">
														<v-badge avatar left overlap>
															<template v-slot:badge>
																<v-avatar>
																	<b-badge href="#" :variant="itemsDataC.color_ignition"  style="color:transparent">X</b-badge>
																</v-avatar>
															</template>
															<v-avatar size="40" v-if="itemsDataC.img_constructeur">
																<v-img :src="itemsDataC.img_constructeur"></v-img>
															</v-avatar>
															<v-avatar size="40" v-else>
																<v-img src="media/icon/cars.png"></v-img>
															</v-avatar>
														</v-badge>
														{{GetNameOrMat(itemsDataC)}}
													</a>
												</div>
												<div class="d-flex align-items-center py-lg-0 py-2" >
													<div class="d-flex flex-column text-right">
														<span class="text-dark-50 font-weight-bolder font-size-h6">
															<span v-if="itemsDataC.get_gps_status_info">
																<font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['far', 'wifi']"/>
															</span>
															<span v-if="!itemsDataC.get_gps_status_info">
																<font-awesome-icon style="color:#F64E60" class="mr-2" :icon="['far', 'wifi-slash']"/>
															</span>
															
															<span :style="{color : itemsDataC.color_motion}">
																<font-awesome-icon class="mr-2" :icon="['far', 'road']"/>
															</span>
														</span>
													</div>
												</div>
												<span class="text-muted font-weight-bold font-size-sm" style="width:100%;font-size:9px" >
													<img style="width: 17px;" :src="itemsDataC.speed == 0 ? 'media/icon/parking-icon.png' : 'media/icon/pos-icon-move.png'" />  {{itemsDataC.adresse}}
												</span>
												<span class="d-flex text-left" style="width: 100%; font-size: 15px;padding: 0px 0 0 1px;padding: 6px 0 0 0px;" >
													<b style="width: 30px !important;position: relative;height: 30px;display: inline-block;" @click="showTrajetSidebar(itemsDataC.id)"><font-awesome-icon :icon="['fas', 'book-open']" /></b>
													<b style="width: 30px !important;position: relative;height: 30px;display: inline-block;" @click="showStatisticsSidebar(itemsDataC.id)"><font-awesome-icon :icon="['far', 'analytics']" /></b>
												</span>
												
											</div>
										</div>
									</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-9" style="padding: 0;">
				<l-map ref="myMapDetail" v-bind:style="{width: '100%',height: Height}" :zoom="zoom" v-if="itemsDataCarsMaps.length !=0" :center="center" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated">
						<v-rotated-marker  
							ref="marker"
							v-for="(itemsDataCar,key) in itemsDataCarsMaps"
							:key="key" 
							:lat-lng="[itemsDataCar.location.lat,itemsDataCar.location.lng]" 
							:icon="itemsDataCar.ignition == 0 ? icon : icon_move" 
							:rotationAngle="itemsDataCar.ignition == 0 ? 0 : itemsDataCar.heading">
							<l-popup ref="popup" :options="{closeOnClick: false,autoClose: false, openPopup:true}">
								<img style="width: 12px;" :src="itemsDataCar.speed == 0 ? 'media/icon/parking-icon.png' : 'media/icon/pos-icon-move.png'" /> <b style="font-size: 10px;">{{GetNameOrMat(itemsDataCar)}}</b>
							</l-popup>
						</v-rotated-marker>
					<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
					<l-control class="example-custom-control">
						<div style="background-color: #fff;padding: 8px;border-radius: 5px;border: 1px solid #eee;margin: 0px 0 0 0;">
							<button id="SeetingsTooltip" class="btn btn-sm btn-icon btn-bg-light btn-text-primary" type="button" st @click="showModal()">
								<i style="color:#F64E60;" class="fa fa-cog"></i>
							</button>
							<b-tooltip placement="left" target="SeetingsTooltip">
								{{$tc('OVERVIEW.TEXT.MODAL_TITLE_SETTINGS')}}
							</b-tooltip>
							<br>
							<button id="ZoomMapsTooltip" style="margin-top: 5px;" type="button" st class="btn btn-sm btn-icon btn-bg-light btn-text-primary" @click="ResetMapsViewZoom()">
								<i style="color:#ffc700;" class="fa fa-eye"></i>
							</button>
							<b-tooltip placement="left" target="ZoomMapsTooltip">
								{{$tc('OVERVIEW.TEXT.MODAL_ZOOM')}}
							</b-tooltip>
						</div>
					</l-control>
					
				</l-map>
			</div>
		</div>
		<b-modal ref="modal_Settings" :title="$tc('OVERVIEW.TEXT.MODAL_TITLE_SETTINGS')">
			<h5>Afficher vehicle par :</h5>
			<select class="form-control" v-model="SettingsNameConfig" @change="SettingsNameDevise()">
				<option value="name">Nom</option>
				<option value="immatriculation">Matricule</option>
				<option disabled>Driver</option>
			</select>
		</b-modal>
		<b-modal ref="modal_Cars" :title="name_cars" hide-footer>
			<div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
				<label class="d-flex align-items-center fs-6 fw-bold mb-2">
					<span class="required">Nom</span>
				</label>
				<input v-model="name_cars" type="text" class="form-control form-control-solid" placeholder="Nom" name="">
			</div>
			<div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
				<label class="d-flex align-items-center fs-6 fw-bold mb-2">
					<span class="required">N° d'immatriculation</span>
				</label>
				<input v-model="matricule_cars" type="text" class="form-control form-control-solid" placeholder="**** TU ***" name="">
			</div>
			<div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
				<label class="d-flex align-items-center fs-6 fw-bold mb-2">
					<span class="required">Odomètre</span>
				</label>
				<input v-model="odometre_cars" type="number" class="form-control form-control-solid" placeholder="En mètre" name="">
			</div>
			<footer class="modal-footer">
				<button type="button" class="btn btn-secondary" @click="hideModalDataCars();">Annuler</button>
				<button type="button" class="btn btn-primary" @click="UpdateDataCars()">OK</button>
			</footer>
			
		</b-modal>
	</div>
</template>
<style>
.leaflet-popup {top: 55px !important;}
.leaflet-popup-tip-container {
   top: 0px !important;
}
.leaflet-popup-tip {
   box-shadow: none !important;
}
.leaflet-popup:before {
    content: "";
    position: absolute;
    border: 13px solid transparent;
    border-bottom-color: white;
    bottom: 0px;
    margin-left: -9px;
}
.leaflet-popup-content-wrapper {
    margin-left: 49px;
}
.leaflet-popup {
    top: 9px !important;
}
/*.leaflet-popup-content-wrapper{
    padding: 0;
	margin-top: -9px;
}*/



.offcanvas-tlmtk  {
  background: #eee;
  -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
  width: 500px;
}
.offcanvas-tlmtk  {
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;

}
.offcanvas-tlmtk  {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
}
.offcanvas-tlmtk , .offcanvas-tlmtk -mobile {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.offcanvas-tlmtk.offcanvas-on {
  -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  overflow: hidden;
}

</style>
<script>
import TrajetView from '@/view/pages/trajet/Trajet.vue'
import StatisticsView from '@/view/pages/statistics/Statistics.vue'


import Cookie from "@/core/services/localstorage.module";
import functionAssets from "@/core/services/function.assets.module.js";
import propertiesCar from "@/core/services/properties.car.module.js";
import axios from "axios";
import { LMap, LTileLayer,LPopup,LControl } from 'vue2-leaflet';
import L from 'leaflet';
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
//import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import LoadingProcess from "@/view/pages/Loading-process.vue";

import { library } from '@fortawesome/fontawesome-svg-core'
/*
import { faCircle as fasFaCircle } from '@fortawesome/pro-solid-svg-icons'
import { faCircle as farFaCircle } from '@fortawesome/pro-regular-svg-icons'
import { faCircle as falFaCircle } from '@fortawesome/pro-light-svg-icons'
import { faCircle as fadFaCircle } from '@fortawesome/pro-duotone-svg-icons'*/

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { faRoad as fasRoad,faWifiSlash as fasWifiSlash,faWifi as fasWifi,faRouter as fasRouter,faMap as fasMap} from '@fortawesome/pro-solid-svg-icons'
import { faRoad as farRoad,faWifiSlash as farWifiSlash,faWifi as farWifi,faRouter as farRouter,faMap as farMap} from '@fortawesome/pro-regular-svg-icons'
//import {  } from '@fortawesome/pro-solid-svg-icons'

library.add(fasWifi, fasRouter,farWifi,farRouter,farWifiSlash,fasWifiSlash,fasRoad,farRoad,fasMap,farMap)




import moment from 'moment'; 
moment.locale(Cookie.GetCookie('language'));
moment.updateLocale('fr', {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});
/*const pause = ms => new Promise(resolve => setTimeout(resolve, ms))*/
const avatars = [
    '?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelGreen&clotheType=ShirtScoopNeck&eyeType=Wink&eyebrowType=UnibrowNatural&facialHairColor=Black&facialHairType=MoustacheMagnum&hairColor=Platinum&mouthType=Concerned&skinColor=Tanned&topType=Turban',
    '?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Gray02&clotheType=ShirtScoopNeck&eyeType=EyeRoll&eyebrowType=RaisedExcited&facialHairColor=Red&facialHairType=BeardMagestic&hairColor=Red&hatColor=White&mouthType=Twinkle&skinColor=DarkBrown&topType=LongHairBun',
    '?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Black&clotheType=ShirtVNeck&eyeType=Surprised&eyebrowType=Angry&facialHairColor=Blonde&facialHairType=Blank&hairColor=Blonde&hatColor=PastelOrange&mouthType=Smile&skinColor=Black&topType=LongHairNotTooLong',
    '?accessoriesType=Round&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=Overall&eyeType=Close&eyebrowType=AngryNatural&facialHairColor=Blonde&facialHairType=Blank&graphicType=Pizza&hairColor=Black&hatColor=PastelBlue&mouthType=Serious&skinColor=Light&topType=LongHairBigHair',
    '?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly',
  ];

export default {
	components: {
		'font-awesome-icon': FontAwesomeIcon,
		'v-rotated-marker': Vue2LeafletRotatedMarker,
		LoadingProcess,
		TrajetView,
		StatisticsView,
		LMap,
		LPopup,
		LTileLayer,
		LControl,
		
		//'l-marker-cluster': Vue2LeafletMarkerCluster

		//LMarkerCluster,
    /*LMarker,*/
    },
	data: () => ({
		TrajetId:undefined,
		StatId:undefined,
		NameOrMatriculation: true,
		tabIndex: 0,
		Height: '1000px',
		showLeft: false,
		showLeftMargin: "",
		url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		attribution:
		'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		zoom: 8,
		zoomS: 8,
		center: [35.3359135,9.665841],
		centerS: [35.3359135,9.665841],
		bounds: null,
		icon: L.icon({
			iconUrl: 'media/icon/stoped.png',
			iconSize: [37, 37],
			iconAnchor: [18, 37]
		  }),
		icon_move: L.icon({
			iconUrl: 'media/icon/pos-icon-move.png',
			iconSize: [37, 37],
			iconAnchor: [18, 37]
		  }),
	
		name: "treeselect-6",
		valueGpOptions: [],
		//vueOne: true,
		valueGpOptionsSelect: "",
		SelectGpOptions: [],
		FilterGroupeId: "",
		FilterName: "",
		request : null,
		//mindata : [],
		mindataShow : [],
		items : [],
		active: [],
		avatar: null,
		open: [],
		users: [],
		itemsData: [],
		clusterOptions : {},
		itemsDataCars: {},
		itemsDataCarsMaps: {},
		itemsDataCarsMapsS: {},
		itemsDataCarsLeft: {},
		totalRows: 1,
        currentPage: 1,
        perPage: 1000000000,
        pageOptions: [10, 50, 100,500],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
		fields: [],
		moment: moment,
		TimeReloadData: 50000,
		TimeReloadDataSettings: "",
		mindata: {},
		SettingsNameConfig: "",
		AlertDrive:{},
		AlertMaintenance :{},
		AlertPaye:{},
		odometre_cars: "",
		matricule_cars: "",
		name_cars: "",
		loadingBar : false,
		
    }),
	destroyed() {
		this.$destroy() 
		clearInterval(this.TimeReloadDataSettings);
	},
	computed: {
		sortOptions() {
			return this.fields
			.filter(f => f.sortable)
			.map(f => {
				return { text: f.label, value: f.key }
			})
		},
		selected () {
			var mindata = Cookie.GetCookie('mindata');
			for (var i in mindata){
				if(mindata[i].id == this.active[0]){
					//console.log( mindata[i]);
					//live
					return mindata[i];
				}
			}
			return "";
		},
	},
	
	async mounted() {
		this.loadingBar = true;
		this.clusterOptions = {}
		this.$nextTick(() => {
			for(var j in this.$refs.marker){
				this.$refs.marker[j].mapObject.openPopup();
			}
		});
		//var body = document.body,
		//html = document.documentElement;
		this.Height = screen.height;//Math.min( body.scrollHeight, body.offsetHeight,html.clientHeight, html.scrollHeight, html.offsetHeight );
		this.Height = this.Height < 500 ? 500 : this.Height;
		this.Height = (this.Height)+"px";
	
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		//await this.NewToken();
		
		
		let BLockCars = document.querySelector('#BLockCars');
		let kt_aside = document.querySelector('#kt_aside');
		let widthBLockCars = BLockCars.offsetWidth;
		let widthkt_aside = kt_aside.offsetWidth;
		
		
		this.showLeftMargin = (widthBLockCars + widthkt_aside - 12)+"px";
		this.fields = [
			{ key: 'vehicle', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.VEHICLE, sortable: true, sortDirection: 'desc' },
			{ key: 'range_last_ignition', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.STATUS, sortable: false, 'class': 'text-center' },
			{ key: 'param1_text', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.LAST_MESSAGE, sortable: false, 'class': '' },
			{ key: 'adresse', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.LOCATION, sortable: true, 'class': 'text-center' },
			{ key: 'speed_text', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.SPEED, sortable: false, 'class': 'text-center' },
		]
		this.FilterGroupeId = "";
		//this.InitDataCars();
		this.GetDataCars();
		this.GroupeGpOptions();
		
		this.TimeReloadDataSettings = setInterval(() => {
			this.GetDataCars();
		}, this.TimeReloadData);
		setTimeout(() => {
			//this.GetDataCars();
			//this.$refs.clusterRef.mapObject.refreshClusters()
		}, 1000);
		this.mindata = Cookie.GetCookie('mindata');
		KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
		
		this.SettingsNameConfig == this.NameOrMatriculation == true ? "name" : "immatriculation";
		//document.getElementById("myDiv").style.maxWidth = "100%";
	},
	created() {

    },
	methods: {
		async showTrajetSidebar(Id) {
			await this.$nextTick();
			this.TrajetId = Id;
			this.$root.$emit('bv::toggle::collapse', 'trajet-sidebar')
			this.$forceUpdate()
			await this.$nextTick();

		},
		async showStatisticsSidebar(Id) {
			//await this.$nextTick();
			this.StatId = Id;
			this.$root.$emit('bv::toggle::collapse', 'statistics-sidebar')
			this.$forceUpdate()
			await this.$nextTick();

		},
		showModal() {
			this.$refs['modal_Settings'].show()
		},
		showModalUpdateCars() {
			this.odometre_cars = this.itemsDataCarsLeft.odometer;
			this.matricule_cars = this.itemsDataCarsLeft.immatriculation;
			this.name_cars = this.itemsDataCarsLeft.vehicle;
			this.$refs['modal_Cars'].show();
		},
		UpdateDataCars() {
			this.loadingBar = true;
			axios.post("/controleur/core/calldataV2.php?which=updatecar&GeneralParams=name,"+this.name_cars+",;immatriculation,"+this.matricule_cars+",;odometer_update,"+this.odometre_cars+",;&id="+this.itemsDataCarsLeft.id).then(async () => {
				this.$refs['modal_Cars'].hide();
				this.loadingBar = false;
			}).catch((error) => {  
				if (error.response) {
					if (error.response.status == 500) {
						Cookie.DeleteCookie();
						this.$router.push('/login');
					}
				}
			});
			
		},
		hideModalDataCars() {
			this.$refs['modal_Cars'].hide();
		},
		ResetMapsViewZoom() {
			this.zoom = 8;
			this.center = [35.3359135,9.665841];
		},
		GetNameOrMat(D) {
			if(this.NameOrMatriculation == true){
				return D.vehicle
			}
			return D.immatriculation;
		},
		SettingsNameDevise() {
			this.NameOrMatriculation = this.SettingsNameConfig == "name" ? true : false;
			this.GetDataCars();
		},
		setActiveTab(event) {
			const tab = event.target.closest('[role="tablist"]');
			const links = tab.querySelectorAll(".nav-link");
			// remove active tab links
			for (let i = 0; i < links.length; i++) {
				links[i].classList.remove("active");
			}

			// set clicked tab index to bootstrap tab
			this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

			// set current active tab
			event.target.classList.add("active");
			
		},
		zoomUpdated (zoom) {
		  this.zoom = zoom;
		},
		zoomUpdatedS (zoomS) {
		  this.zoomS = zoomS;
		},
		OpenLeftMenu (id) {
			this.GetAlertCars(id);
			for(var i in this.itemsDataCars){
				/*if(this.itemsDataCars[i].id == id){
					this.itemsDataCarsLeft = this.itemsDataCars[i];
					this.itemsDataCarsMaps = {};
					this.itemsDataCarsMaps[0] = this.itemsDataCars[i];
					this.showLeft = true;
					this.zoom = 12;
					this.center = [this.itemsDataCars[i].location.lat,this.itemsDataCars[i].location.lng];
					setTimeout(() => {
						this.zoom = 12;
						this.center = [this.itemsDataCars[i].location.lat,this.itemsDataCars[i].location.lng];
					}, 1000);
					return;
				}*/
				if(this.itemsDataCars[i].id == id){
					this.itemsDataCarsLeft = this.itemsDataCars[i];
					this.itemsDataCarsMapsS = {};
						this.itemsDataCarsMapsS[0] = this.itemsDataCars[i];
						//this.showLeft = true;
						
						this.centerS = [this.itemsDataCars[i].location.lat,this.itemsDataCars[i].location.lng];
						this.zoomUpdatedS(12);
					/*setTimeout(() => {
						console.log(this.$refs.myMapDetail)
						this.$refs.myMapDetail.mapObject.refreshClusters()
					}, 2000);*/
					setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 1000);
					return;
				}
			}
			
			
		},
		CloseLeftMenu () {
			this.itemsDataCarsMaps = this.itemsDataCars;
			this.showLeft = false;
			this.zoom = 8;
			this.center = [35.3359135,9.665841];
		},
		centerUpdated (center) {
		  this.center = center;
		},
		centerUpdatedS (centerS) {
		  this.centerS = centerS;
		},
		boundsUpdated (bounds) {
		  this.bounds = bounds;
		},
		FilterByGroupe () {
			this.GetDataCars();
		},
		ClearFilterName () {
			this.FilterName = "";
			this.GetDataCars();
		},
		ClearFilterGroup () {
			this.FilterGroupeId = "";
			this.GetDataCars();
		},
		FilterByName () {
			this.GetDataCars();
		},
		GetRusultFilterByName (CH1) {
			var C1 = CH1.toUpperCase();
			var FilterName = this.FilterName.toUpperCase();
			var v3 = C1.search(FilterName);
			return v3 > -1 ? true : false;
		},

		async GetAlertCars(id) {

			axios.post("controleur/core/calldataV2.php?which=openalert&ids="+id).then(async (response) => {
				this.AlertDrive = response.data[id].drive;
				this.AlertMaintenance = response.data[id].maintenance;
				this.AlertPaye = response.data[id].paye;
			}).catch((error) => {    
				if (error.response) {
					if (error.response.status == 500) {
						Cookie.DeleteCookie();
						this.$router.push('/login');
					}
				}
			});
		},
		async GetDataCars() {
				this.loadingBar = true;
				axios.post("controleur/core/calldataV2.php?which=vehiclelist&id=0").then(async (response) => {
					var itemsDataCars = [];
					var mindata = Cookie.GetCookie('mindata');
					for(var i in response.data){
						console.log(await propertiesCar.GetData(response.data[i]));
						if(this.FilterGroupeId == ""){
							if(this.FilterName == ""){
								itemsDataCars.push(await propertiesCar.GetData(response.data[i]));
							}else if(this.GetRusultFilterByName(mindata[i].name)){
								itemsDataCars.push(await propertiesCar.GetData(response.data[i]));
							}
						}else if(this.FilterGroupeId == mindata[i].id_groupe ){
							if(this.FilterName == ""){
								itemsDataCars.push(await propertiesCar.GetData(response.data[i]));
							}else if(this.GetRusultFilterByName(mindata[i].name)){
								itemsDataCars.push(await propertiesCar.GetData(response.data[i]));
							}
							
						} 
						this.loadingBar = false;
					}
					this.itemsDataCars = itemsDataCars;
					this.itemsDataCarsMaps = itemsDataCars;
					console.log("-+-+-+++-+++");
					//console.log(this.$refs);
					for(var j in this.$refs.marker){
						this.$refs.marker[j].mapObject.openPopup();
					}
					
				}).catch((error) => { 
					console.log("error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error error ");
					console.log(error);
					if (error.response) {
						if (error.response.status == 500) {
							Cookie.DeleteCookie();
							this.$router.push('/login');
						}
					}
				});
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length
			this.currentPage = 1
		  },
		async fetchUsers (item) {
			item.children = functionAssets.GetCarsByGp(item.id);
			//console.log(item);
		},
		randomAvatar () {
			this.avatar = avatars[Math.floor(Math.random() * avatars.length)]
		},
		GroupeGpOptions () {
			this.SelectGpOptions = functionAssets.GpCars();
			console.log(this.SelectGpOptions);
			this.items = functionAssets.GpCars();
			console.log(this.SelectGpOptions);
		},
		/*async SelectUpdate () {
			//const request = axios.CancelToken.source();
			this.request.cancel("Optional message");
			//await this.NewToken();
			this.itemsData = [];
			this.mindata = [];
			var res = await functionAssets.GetGroupeSubGroup(this.valueGpOptions);
			this.FilterGroupeId = res;
			await this.InitDataCars();
			//console.log(this.items);
		},*/
	},
}
</script>