<template>
	<div>
        <div class="card card-custom gutter-b mt-10">
            
                <div class="card-body">

        <br>
        <div class="row">
            <div class="col-md-4">
                <treeselect v-model="CarsSelect" :multiple="false" :options="options"/>
            </div>
            <div class="col-md-3">
                <datetime type="date" :placeholder="$t('TEXT_ALL.DATE_START')" v-model="dateStart" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
            </div>
            <div class="col-md-3">
                <datetime type="date" :placeholder="$t('TEXT_ALL.DATE_END')" v-model="dateEnd" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
            </div>
            <div class="col-md-2">
                <button type="submit" class="btn btn-success font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" @click="GetStatistics();"> Appliquer </button>
            </div>
        </div>
   </div>
</div>

<div class="row">
			<!--begin::Aside-->
			<div class="col-12" >
				 <div id="chart">
					<apexchart ref="Chart" type="area" height="300" :options="chartOptions" :series="series" @mouseover="UpdateChartIndex"></apexchart>
				  </div>
			</div>
			
			<!--begin::Content-->
			<div class="col-12">
				<!--begin::Card-->
				<div class="card card-custom card-stretch p-4" style="height: 600px;">
                    <l-map id="maps" v-model="maps" v-bind:style="{width: '100%',height: Height}" :zoom="zoom"  :center="center" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated">                    
                        <v-rotated-marker v-if="StartIcon.length != 0" 
							ref="marker"
							:lat-lng="StartIcon" 
							:icon="icon_start" 
							:rotationAngle="0">
							<!--<l-popup ref="popup" :options="{closeOnClick: false,autoClose: false, openPopup:true}">
								<img style="width: 12px;" :src="itemsDataCar.speed == 0 ? 'media/icon/parking-icon.png' : 'media/icon/pos-icon-move.png'" /> <b style="font-size: 10px;">{{GetNameOrMat(itemsDataCar)}}</b>
							</l-popup>-->
						</v-rotated-marker>
                        <v-rotated-marker  v-if="StartIcon.length != 0" 
							ref="marker"
							:lat-lng="EndIcon" 
							:icon="icon_stop" 
							:rotationAngle="0">
							<!--<l-popup ref="popup" :options="{closeOnClick: false,autoClose: false, openPopup:true}">
								<img style="width: 12px;" :src="itemsDataCar.speed == 0 ? 'media/icon/parking-icon.png' : 'media/icon/pos-icon-move.png'" /> <b style="font-size: 10px;">{{GetNameOrMat(itemsDataCar)}}</b>
							</l-popup>-->
						</v-rotated-marker>
                        <l-polyline :lat-lngs="polyline.latlngs" :color="polyline.color" :weight="polyline.weight"></l-polyline>
                        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                        
                    </l-map>
				</div>
			</div>
			<!--end::Content-->
		</div>
    </div>
</template>

<script>
/*import axios from "axios";
//import Cookie from "@/core/services/localstorage.module";
import functionAssets from "@/core/services/function.assets.module.js";*/
import propertiesCar from "@/core/services/properties.car.module.js";
import axios from "axios";

import { LMap, LTileLayer,LPolyline} from 'vue2-leaflet';
import L from 'leaflet';
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
/*
import { faCircle as fasFaCircle } from '@fortawesome/pro-solid-svg-icons'
import { faCircle as farFaCircle } from '@fortawesome/pro-regular-svg-icons'
import { faCircle as falFaCircle } from '@fortawesome/pro-light-svg-icons'
import { faCircle as fadFaCircle } from '@fortawesome/pro-duotone-svg-icons'*/

//import { library } from '@fortawesome/fontawesome-svg-core'
//import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
//import { fas} from '@fortawesome/pro-solid-svg-icons'
//import { far} from '@fortawesome/pro-regular-svg-icons'
//import {  } from '@fortawesome/pro-solid-svg-icons'

//library.add(fas,far)

import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

import Cookie from "@/core/services/localstorage.module";
import moment from 'moment'; 
moment.locale(Cookie.GetCookie('language'));
moment.updateLocale('fr', {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});


export default {
	components: {
        datetime: Datetime,
        //'font-awesome-icon': FontAwesomeIcon,
        LMap,
		LTileLayer,
        LPolyline,
        'v-rotated-marker': Vue2LeafletRotatedMarker,
    },
	data: () => ({
		xaxisData : [],
		SerieData : [],
		series: [{
            name: 'Vitesse',
            data:  []//this.SerieData
          }],
          chartOptions: {
            chart: {
              type: 'area',
			 /* events: {
					 mouseMove(event, chartContext, config) {
						this.UpdateChartIndex(config.dataPointIndex);
						console.log(config.dataPointIndex)
						console.log(this.SerieData[config.dataPointIndex])
					}
				},*/
              stacked: false,
              height: 350,
              zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
              },
              toolbar: {
                autoSelected: 'zoom'
              }
            },
            dataLabels: {
              enabled: false
            },
            markers: {
              size: 0,
            },
            title: {
              text: 'Statistiques du véhicule',
              align: 'centre'
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return val//(val / 1000000).toFixed(0);
                },
              },
              title: {
                text: 'Vitesse Km/h'
              },
            },
			annotations: {},
            xaxis: {
              categories: [],//this.xaxisData,
			  
	
            },
            tooltip: {
              shared: false,
              y: {
                formatter: function (val) {
                  return val+" Km/h"//(val / 1000000).toFixed(0)
                }
              }
            }
		},
	

		IdSubmited:"",
		id:"",
		Trajet: '',
        TrajetDetail : '',
        moment: moment,
        Height: '1000px',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		attribution:
		'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		zoom: 8,
		center: [35.3264774,9.5552708],
		bounds: null,
        StartIcon : [],
        EndIcon : [],
		maps:null,
        polyline: {
            latlngs: [],
            weight: 5,
            color: '#069fb6'
        },
        icon_start: L.icon({
			iconUrl: 'media/icon/tripview_af.png',
			iconSize: [30, 42],
			iconAnchor: [18, 37]
		  }),
        icon_stop: L.icon({
			iconUrl: 'media/icon/tripview_bf.png',
			iconSize: [30, 42],
			iconAnchor: [0, 37]
		  }),

        propertiesCar:propertiesCar,
        mindata : [],
        mindataShow : [],
        mindataShowGp : [],
        CarsSelect: [],
        options: [],
        newDate : new Date().toJSON(),
		dateStart : "",
		dateEnd : "",
        tripNumber : 0,
        mileage : 0,
    }),
	destroyed() {

	},
	computed: {
        
    },
	async mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
        this.dateStart = new Date().toJSON();
		this.dateEnd = new Date().toJSON();
        setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 1000);
		//console.log();
		if(this.$el.attributes){
		console.log(this.$el.attributes)
			if(typeof(this.$el.attributes.id) != undefined && typeof(this.$el.attributes.id) != "undefined"){
				//alert("mounted 111111111111111111111111111111111");
				this.IdSubmited = this.$el.attributes.id.value;
				this.CarsSelect = parseInt(this.IdSubmited);
				this.GetStatistics();
			}
		}
        this.GetCarsList();
		this.chartOptions = {
					chart: {
						events: {
								 mouseMove(event, chartContext, config) {
									//console.log(Vue)
									
									//this.UpdateChartIndex(config.dataPointIndex);
									
									console.log(chartContext)
									console.log(config.dataPointIndex)
									console.log(this.SerieData[config.dataPointIndex])
								}
							}
					}
				};
	},
	created() {
		
    },
	methods: {

        UpdateChartIndex (event, chartContext, config) {
			console.log("*************************************************************************************************");
			console.log("*************************************************************************************************");
			console.log("*************************************************************************************************");
			console.log("*************************************************************************************************");
			console.log(config);
		},
		GetCarsList () {
			this.options = propertiesCar.SelectBoxFormat();
		},

        
        async GetStatistics() {
		
            if(this.dateStart == "" || this.dateEnd =="") {
				alert("Veuillez remplir la date");
				return;
			}
			var dateStart = new Date(moment(this.dateStart).startOf('day').toISOString()).getTime();
			var dateEnd = new Date(moment(this.dateEnd).endOf('day').toISOString()).getTime();
			if(dateStart > dateEnd) {
				alert("Date invalide");
				return;
			}
            if(this.CarsSelect == ""){
                alert("Veuillez sélectionner un véhicule");
                return;
            }
            if(!Number.isInteger(this.CarsSelect)){
                alert("Veuillez sélectionner un véhicule");
                return;
            }
            axios.defaults.headers["Content-Type"] =  "application/json";
		    axios.defaults.withCredentials = true;
			axios.get("controleur/core/calldataV2.php",{params: {id: this.CarsSelect,start:dateStart,end:dateEnd,which: "statistics",attribute:"gps_latitude,gps_longitude,gps_speed,param1"}}).then((response) => {
                this.SerieData = [];
				
				let SerieData = [];
				let xaxisData = [];
				let annotations = [];
				let dataF = response.data[this.CarsSelect].data
				var i = 0;
				for(var j in dataF){
					this.SerieData[i] = dataF[j];
					i = i+ 1;
					SerieData.push(this.moment(parseFloat(parseFloat(j))).format("DD/MM/YYYY hh:mm:ss"));
					xaxisData.push(parseInt(dataF[j].gps_speed));
					if(dataF[j].param1 == "1100"){
						annotations.push({
							x: this.moment(parseFloat(parseFloat(j))).format("DD/MM/YYYY hh:mm:ss"),
							strokeDashArray: 0,
							borderColor: "#ed1c24",
							label: {
								borderColor: "#ed1c24",
								style: {
									color: "#fff",
									background: "#ed1c24"
								},
								text: "Moteur arrêté"
							}
						});
					}else if(dataF[j].param1 == "1110"){
						annotations.push({
							x: this.moment(parseFloat(parseFloat(j))).format("DD/MM/YYYY hh:mm:ss"),
							strokeDashArray: 0,
							borderColor: "#1bc5bd",
							label: {
								borderColor: "#1bc5bd",
								style: {
									color: "#fff",
									background: "#1bc5bd"
								},
								text: "Moteur allumé"
							}
						});
					}
				}
				

				
				this.chartOptions = {
					annotations : {xaxis: annotations},
					xaxis: {
						categories: SerieData
					}
				};
				this.$refs.Chart.updateSeries([{ data: xaxisData }]);

            }).catch((error) => {    
                if (error.response) {
                    if (error.response.status == 500) {
                        //Cookie.DeleteCookie();
                        this.$router.push('/login');
                    }
                }
            });
        },
        //https://pro.sayarte.ch/2.6/php/controleur/core/calldataV2.php?id=4162&attribute=gps_latitude%2Cgps_longitude%2Cgps_speed%2Cfuel_level%2Cpeak_engine_speed%2Cparam1%2Cgps_heading&start=1678641266000&end=1678642410000+&which=drivescore
        GetDetailTrajet(start,stop) {
            axios.defaults.headers["Content-Type"] =  "application/json";
		    axios.defaults.withCredentials = true;

            var id_car = this.CarsSelect;

            axios.post("controleur/core/calldataV2.php?id="+id_car+"&attribute=gps_latitude,gps_longitude,gps_speed,fuel_level,peak_engine_speed,param1,gps_heading&start="+start+"&end="+stop+"+&which=drivescore").then(async (response) => {
                this.TrajetDetail = response.data.trips;
                this.polyline.latlngs = [];
                var StartIcon=[];
                var endIcon=[];
                for(var i in response.data[id_car].data){
                    this.polyline.latlngs.push([response.data[id_car].data[i].gps_latitude,response.data[id_car].data[i].gps_longitude]);
                    if(StartIcon == ""){
                        StartIcon = [response.data[id_car].data[i].gps_latitude,response.data[id_car].data[i].gps_longitude];
                        this.center = StartIcon;
                    }else{
                        endIcon = [response.data[id_car].data[i].gps_latitude,response.data[id_car].data[i].gps_longitude];
                    }
                }
                this.StartIcon = StartIcon;
                this.EndIcon = endIcon;
                setTimeout(() => {
                    this.center = StartIcon;
                    this.zoom = 10;
                    
                }, 1500);
                
            }).catch((error) => {   
                console.log(error); 
                if (error.response) {
                    if (error.response.status == 500) {
                        Cookie.DeleteCookie();
                        this.$router.push('/login');
                    }
                }
            });
        },
        zoomUpdated (zoom) {
            this.zoom = zoom;
        },
        centerUpdated (center) {
         this.center = center;
        },
        boundsUpdated (bounds) {
         this.bounds = bounds;
        },
    }
}
</script>
